<template>
  <div class="addTicket">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>门票分类</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" :model="formList" label-width="80px">
      <el-form-item label="门票分类名称">
        <el-input
          v-model="formList.cateName"
          placeholder="门票请输入分类名称"
        ></el-input>
      </el-form-item>


    </el-form>
    <el-button type="primary" @click="onSave">保存</el-button>
    <el-button type="primary" @click="$router.go(-1)">返回</el-button>
  </div>
</template>
<script>
const platform = [{value:1,name:'小程序'}, {value:2,name:'H5'}, {value:3,name:'IOS'},{value:4,name:'Android'},{value:5,name:'PC'}];
import { addTicketCate, ticketDetails } from "../../api/ticket";
export default {
  name: "addTicketCate",
  data() {
    return {
      formList: {
        cateId:'',
        cateName: '',
      },
    };
  },
  created() {
      console.log(this.$route.params);
 this.formList.cateId = this.$route.params.id
 this.formList.cateName = this.$route.params.cateName
  },
  methods: {
      async onSave(){
          const {data} = await addTicketCate(this.formList)
          this.$message.success(data.msg);
          this.$router.go(-1)
      }
  },
};
</script>
<style lang="less" scoped>
.addTicket {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/ .el-form {
    margin-top: 50px;
    .el-form-item{
          display: flex;
    align-items: center;
    justify-content: flex-start;
    }
          .el-form-item__label{
        
 width: 8.875rem !important;
 text-align: center !important;

      }
    .el-form-item__content {
      width: 800px;
      margin-left: 0 !important;
      .quill-editor {
        height: 500px;
        .ql-container {
          height: 400px;
        }
      }
    }
  }
}
</style>